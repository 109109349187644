<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process-mst-multi
            :editable="editable"
            label="상위공종"
            multiple="single"
            name="upProessCd"
            v-model="searchParam.upProessCd">
          </c-process-mst-multi>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process-mst-multi
            :editable="editable"
            label="공종"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process-mst-multi>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="processTable"
      title="공종 목록"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="processCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from "@/js/selectConfig";
export default {
  name: "process-mst-pop",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: "multiple",
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return "auto";
      },
    },
  },
  data() {
    return {
      listUrl: "",
      grid: {
        merge: [
          { index: 0, colName: 'constructTypeCd' },
          { index: 1, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'constructTypeName',
            field: 'constructTypeName',
            label: '공사형태',
            align: 'center',
            sortable: true,
          },
          {
            name: "upProcessName",
            field: "upProcessName",
            label: "상위공종",
            align: "center",
            sortable: true,
          },
          {
            name: "processName",
            field: "processName",
            label: "공종",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
        height: "100%",
      },
      searchParam: {
        upProcessCd: '',
        processCd: '',
        useFlag: 'Y',
      },
      editable: true,
      searchUrl: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    plantDisabled() {
      return Boolean(this.popupParam.plantCd)
    },
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - this.$refs["searchbox"].$el.offsetHeight - 50;
      if (height < 400) {
        height = 800;
      }

      this.grid.height = height + "px";
    },
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.process.listPop.url;
      
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.type = "GET";
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      });
    },
    select() {
      let selectData = this.$refs["processTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "공종을 선택하세요.", // 공종을 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$emit("closePopup", selectData);
      }
    },
  },
};
</script>
